<template>
  <div class="party">
    <div class="image">
      <img src="../assets/img/partyzg.png" style="width: 100%; display:block" />
    </div>
    <div class="layout">
      <div class="contentParty">
        <div class="listHd clearfloat">
          <div class="fl listHdGrop">
            {{$t('m.listPageOption')}}：<a href="/" style="font-weight: 700; color: #303133;">{{$t('m.listPageHome')}}</a> > 
            <span>&nbsp;党建动态 </span>
          </div>
        </div>
        <div class="main">
          <div class="main_header">
            <div style="display: flex;">
              <div class="main_header_left">
                <el-carousel height="450px" ref="carousel" @change="carouselChange">
                  <el-carousel-item v-for="(item, index) in PartyList" :key="index">
                    <div style="width: 100% ; height: 100% ;">
                      <img :src="item.thumb1" style="width: 100% ; height: 100% ;" />
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="main_header_right">
                <div class="main_header_right_header" :class="{ active: activeHeader == 0 }">
                  <a v-if="HeaderTAD.url && HeaderTAD.url !== ''" :href="HeaderTAD.url">
                    <h2>{{HeaderTAD.title}}</h2>
                  </a>
                  <a v-else :href="`/detail/${HeaderTAD.entTChannelUuid}/${HeaderTAD.entTArticleUuid}`">
                    <h2>{{HeaderTAD.title}}</h2>
                  </a>
                  <span>
                    <a href="/listTwo/01810008" class="spn">更多</a>
                  </span>
                </div> 
                <a v-if="HeaderTAD.url && HeaderTAD.url !== ''" :href="HeaderTAD.url">
                  <p>{{HeaderTAD.artDesc}}</p>
                </a>
                <a v-else :href="`/detail/${HeaderTAD.entTChannelUuid}/${HeaderTAD.entTArticleUuid}`">
                  <p>{{HeaderTAD.artDesc}}</p>
                </a>
                <ul>
                  <li :class="{ active: index == activecar }" v-for="(item, index) in PartyList2" :key="index">
                    <span></span>
                    <a v-if="item.url && item.url !== ''" :href="item.url">
                      {{item.title}}
                    </a>
                    <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                      {{item.title}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="main_column">
            <div class="main_column_news">
              <div class="main_column_news_header">
                <div class="main_column_news_header_img">
                  <img src="../assets/image/党徽.2b046c5.png" alt="">
                </div> 
                <span>纪检监督</span> 
                <span>
                  <a href="/listTwo/01810001" class="spn">更多</a>
                </span>
              </div> 
              <ul>
                <li v-for="(item, index) in partyArtList01" :key="index" >
                  <span></span> 
                  <a v-if="item.url && item.url !== ''" :href="item.url">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                  <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                </li>
              </ul>
            </div>
            <div class="main_column_news">
              <div class="main_column_news_header">
                <div class="main_column_news_header_img">
                  <img src="../assets/image/党徽.2b046c5.png" alt="">
                </div> 
                <span>党建活动</span> 
                <span>
                  <a href="/listTwo/01810003" class="spn">更多</a>
                </span>
              </div> 
              <ul>
                <li v-for="(item, index) in partyArtList03" :key="index" >
                  <span></span> 
                  <a v-if="item.url && item.url !== ''" :href="item.url">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                  <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <div class="main_column_news">
              <div class="main_column_news_header">
                <div class="main_column_news_header_img">
                  <img src="../assets/image/党徽.2b046c5.png" alt="">
                </div> 
                <span>公示公告</span> 
                <span>
                  <a href="/listTwo/01810002" class="spn">更多</a>
                </span>
              </div> 
              <ul>
                <li v-for="(item, index) in partyArtList02" :key="index" >
                  <span></span> 
                  <a v-if="item.url && item.url !== ''" :href="item.url">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                  <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                </li>
              </ul>
            </div> -->
            <div class="main_colum_img">
              <img src="../assets/image/partyMiddle1.jpg" alt="">
            </div>
            
            <div class="main_column_news">
              <div class="main_column_news_header">
                <div class="main_column_news_header_img">
                  <img src="../assets/image/党徽.2b046c5.png" alt="">
                </div> 
                <span>群团</span> 
                <span>
                  <a href="/listTwo/01810004" class="spn">更多</a>
                </span>
              </div> 
              <ul>
                <li v-for="(item, index) in partyArtList04" :key="index" >
                  <span></span> 
                  <a v-if="item.url && item.url !== ''" :href="item.url">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                  <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                </li>
              </ul>
            </div>
            <div class="main_column_news">
              <div class="main_column_news_header">
                <div class="main_column_news_header_img">
                  <img src="../assets/image/党徽.2b046c5.png" alt="">
                </div> 
                <span>榜样力量</span> 
                <span>
                  <a href="/listTwo/01810005" class="spn">更多</a>
                </span>
              </div> 
              <ul>
                <li v-for="(item, index) in partyArtList05" :key="index" >
                  <span></span> 
                  <a v-if="item.url && item.url !== ''" :href="item.url">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                  <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <div class="main_column_news">
              <div class="main_column_news_header">
                <div class="main_column_news_header_img">
                  <img src="../assets/image/党徽.2b046c5.png" alt="">
                </div> 
                <span>知行合一</span> 
                <span>
                  <a href="/listTwo/01810006" class="spn">更多</a>
                </span>
              </div> 
              <ul>
                <li v-for="(item, index) in partyArtList06" :key="index" >
                  <span></span> 
                  <a v-if="item.url && item.url !== ''" :href="item.url">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                  <a v-else :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
                    <p>{{item.title}}</p> 
                    <b>{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</b>
                  </a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
  import {
    getChildChannel,
    getChannelInfoNew,
    articles,
    // picArticles,
    picArticlesRecommend
  } from '@/api/common'

  export default {
    name: 'list',
    data() {
      return {
        activeHeader: 0,
        activecar: -1,
        headertitle: '',
        HeaderTAD: '',
        headerartDesc: '',
        isMenuShow:true,
        isthird: false,
        isClickid: '',
        isClickid2: '',
        slideHdName1: '',
        slideHdName2: '',
        slideHdTitle: '',
        viewSlideHdParent: true,
        slideHdParentName: '',
        slideHdParentUrl:'',
        slideHdName: '',
        hdTitle: '',
        hdName: '',
        pageCont: '',
        active: 0,
        slideHd: {
          title: '新闻动态',
        },
        slideList: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        listData: [],
        PartyList: [],
        PartyList2: [],
        listId: '',
        indexImg: '',
        partyArtList01: [],
        partyArtList02: [],
        partyArtList03: [],
        partyArtList04: [],
        partyArtList05: [],
        partyArtList06: [],
applicationUuid:'',
        lang: 'zh-CN',
        
      }
    },
    filters: {
    ellipsis: function(value) {
      if (!value) return "";
      if (value.length > 120) {
        return value.slice(0, 120) + "...";
      }
      return value;
    },
    ellipsis2: function(value) {
      if (!value) return "";
      if (value.length > 45) {
        return value.slice(0, 45) + "...";
      }
      return value;
    },
  }, 
    mounted() {
      var _this = this
      _this.active = _this.$route.params.id
      _this.listId = _this.$route.params.id
      // _this.getChildChannel()
       let lang = localStorage.getItem('lang');
    if( lang == 'en-US'){
      this.applicationUuid = this.webIdEn
    }
    else {
      this.applicationUuid = this.webId
    }

      _this.getChannelInfoNew()
      _this.articles()
      _this.picArticles()
    },
    methods: {
      carouselChange(val){
        if(val == 0) {
          this.activeHeader = 0
          this.activecar = -1
          // console.log(789, this.activeHeader)
        }
        else{
          this.activeHeader = -1
          this.activecar = val - 1
          // console.log(123456,this.activecar)
        }
        
      },
      // 查询二级栏目
      getChildChannel(id) {
        var _this = this
        let data = {
          applicationUuid: _this.webId,
          parentUuid: id
        }
        getChildChannel(data).then(res => {
          const {
            code,
            data
          } = res
          if (code === 1 && data) {
            _this.slideList = data
            if (_this.slideList.length) {
              _this.viewSlideHdParent = true
              let url = ''
              if (_this.slideList[0].catalog === 1) {
                url = `/listPage/${_this.slideList[0].entTChannelUuid}`
              } else if (_this.slideList[0].catalog === 2) {
                url = `/list/${_this.slideList[0].entTChannelUuid}`
              } else {
                url = `/list/${_this.slideList[0].entTChannelUuid}`
              }
              _this.slideHdParentUrl = url
            } else {
              _this.viewSlideHdParent = false
            }
          }
        })
      },
      getChannelInfoNew() {
        var _this = this
        let data = {
          channelUuid: _this.$route.params.id,
          applicationUuid: this.applicationUuid,
        }
        getChannelInfoNew(data).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.slideHdName = data.name
            if (data.parentUuid == "0") {
              _this.slideHdTitle = data.name
              _this.viewSlideHdParent = false
              if(data.fileName && data.fileName !== ''){
                _this.indexImg = data.fileName
              }
              else{
                _this.indexImg = require('../assets/image/partyBuilding.jpg')
              }
              return
            }

            _this.getChildChannel(data.parentUuid)

            let params = {
              channelUuid: data.parentUuid,
              applicationUuid: this.applicationUuid,
            }
            getChannelInfoNew(params).then(res => {
              const { code, data } = res
              if (code === 1 && data) {
                _this.slideHdParentName = data.name
                _this.slideHdTitle = data.name
                if(data.fileName && data.fileName !== ''){
                  _this.indexImg = data.fileName
                }
                else{
                  _this.indexImg = require('../assets/image/partyBuilding.jpg')
                }
              }
            })
          }
        })
      },
      articles() {
        // 纪检监督
        let data01 = {
          entTApplicationUuid: this.webId,
          channelUuid: '01810001',
          pageNum: 1,
          pageSize: 5
        }
        articles(data01).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            this.partyArtList01 = data.rows
          }
        })
        
        // 公示公告
        let data02 = {
          entTApplicationUuid: this.webId,
          channelUuid: '01810002',
          pageNum: 1,
          pageSize: 5
        }
        articles(data02).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            this.partyArtList02 = data.rows
          }
        })
        
        // 党建活动
        let data03 = {
          entTApplicationUuid: this.webId,
          channelUuid: '01810003',
          pageNum: 1,
          pageSize: 5
        }
        articles(data03).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            this.partyArtList03 = data.rows
          }
        })
        
        // 群团
        let data04 = {
          entTApplicationUuid: this.webId,
          channelUuid: '01810004',
          pageNum: 1,
          pageSize: 5
        }
        articles(data04).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            this.partyArtList04 = data.rows
          }
        })
        
        // 榜样力量
        let data05 = {
          entTApplicationUuid: this.webId,
          channelUuid: '01810005',
          pageNum: 1,
          pageSize: 5
        }
        articles(data05).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            this.partyArtList05 = data.rows
          }
        })
        
        // 知行合一
        let data06 = {
          entTApplicationUuid: this.webId,
          channelUuid: '01810006',
          pageNum: 1,
          pageSize: 5
        }
        articles(data06).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            this.partyArtList06 = data.rows
          }
        })

        // banner新闻
        //新闻动态
        // let data08 = {
        //   entTApplicationUuid: this.webId,
        //   channelUuid: '01810008',
        //   pageNum: 1,
        //   pageSize: 9
        // }
        // articles(data08).then(res => {
        //   const { code, data } = res
        //   if (code === 1 && data) {
        //     this.PartyList = data.rows
        //     this.PartyList2 = data.rows.slice(1, 9)
        //     this.HeaderTAD = data.rows[0]
        //     // this.headertitle = data.rows[0].title
        //     // this.headerartDesc= data.rows[0].artDesc
        //   }
        // })

      },
      picArticles() {
      var _this = this

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01810008',
        pageNum: 1,
        pageSize: 9
      }
        picArticlesRecommend(data01).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.PartyList = data.rows
            _this.PartyList2 = data.rows.slice(1, 9)
            _this.HeaderTAD = data.rows[0]
            // _this.headertitle = data.rows[0].title
            // _this.headerartDesc= data.rows[0].artDesc
          }
        })
      },
      currentChange(e) {
        var _this = this
        _this.pageNum = e
        _this.articles()
      },
    }
  }
</script>
<style>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 3165120 */
  src: url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff2?t=1661313604254') format('woff2'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.woff?t=1661313604254') format('woff'),
       url('//at.alicdn.com/t/c/font_3165120_j24ufv6zcfe.ttf?t=1661313604254') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.party .image {
  border: none;
  display: flex;
  margin: 0 auto;
  max-height: 330px;
  min-width: 1200px;
  position: relative;
  z-index: 99;
}
.listTwo .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.contentParty{
  min-width: 1200px;
  min-height: 300px;
  /* display: flex; */
  /* margin: 20px 0 30px; */
  margin: 0px 0 30px;
}
.left{
  width: 240px;
}
.listMainRight{
width: 940px;
  padding: 0 0 20px 20px;
  overflow: hidden;
}
.listHd {
  height: 50px;
  display: flex;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 50px;
  color: #333;
  opacity: .6;
}
.party .main {
  width: 100%;
  height: 100%;
}
.main_header {
  display: flex;
  background: #fff;
  height: 450px;
}
.main_header_left {
  /* width: 820px; */
  width: 640px;
}
.main_header_right {
  padding: 10px 20px;
  width: 560px;
}
.main_header_right_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: FZXiaoBiaoSong-B05S;
  font-weight: 400;
}
.main_header_right h2 {
  width: 260px;
  font-size: 20px;
  font-family: FZXiaoBiaoSong-B05S;
  font-weight: 700;
  color: #444;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: .1s;
}
.main_header_right h2:hover {
  transform: translateY(2px) translateZ(10px);
  cursor: pointer;
}
.main_header_right_header.active h2 {
  color: #920606;
}
.main_header_right_header>span a {
  color: #920606;
}
.main_header_right p, .main_header_right ul li {
  /* width: 340px; */
  width: 540px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  overflow: hidden;
  color: rgb(68, 68, 68);
}
.main_header_right p {
  font-size: 14px;
  color: #838383;
  line-height: 26px;
  text-indent: 2em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.main_header_right ul li {
  font-size: 16px;
  color: #444;
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main_header_right ul li.active a {
  color: #8b0909 !important;
}
/* 
.main_header_right p, .main_header_right ul li {
  width: 340px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  overflow: hidden;
} */
.main_header_right ul li span {
  border: 1px solid #8b0909;
  border-radius: 50%;
  width: 5px;
  display: inline-block;
  height: 5px;
  background: #8b0909;
  margin-right: 8px;
}
.main_header_left .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.main_header_left .el-carousel__button {
  display: block;
  opacity: .48;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: .3s;
}
.main_header_left .el-carousel__indicators {
  left: unset;
  transform: unset;
  right: 10%;
}
.main_column {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main_column_news {
  margin: 10px 0;
  max-height: 256px;
}
.main_column_news_header {
  width: 570px;
  border-bottom: 1px solid #dedede;
  position: relative;
}
.main_column_news_header_img {
  width: 22px;
  height: 23px;
  position: absolute;
  bottom: 15px;
}
.main_column_news_header_img img {
  width: 100%;
  height: 100%;
}
.main_column_news_header span:first-of-type {
  width: 200px;
  display: inline-block;
}
.main_column_news_header>span {
  line-height: 50px;
  font-size: 20px;
  font-family: FZXiaoBiaoSong-B05S;
  font-weight: 400;
  vertical-align: middle;
  margin-left: 40px;
  color: #920606;
}
.main_column_news_header span:nth-of-type(2) {
  margin-left: 294px;
  font-size: 16px;
}
.main_column_news_header>span {
  line-height: 50px;
  font-size: 20px;
  font-family: FZXiaoBiaoSong-B05S;
  font-weight: 400;
  vertical-align: middle;
  margin-left: 40px;
  color: #920606;
}
.main_column_news_header>span a {
  color: #920606;
}
.main_column ul li span {
  border: 1px solid #8b0909;
  border-radius: 50%;
  width: 5px;
  display: inline-block;
  height: 5px;
  background: #8b0909;
  margin-right: 8px;
}
.main_column ul li p {
  width: 440px;
  display: inline-block;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #444;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: -15px;
  margin-right: 20px;
}
.main_column ul li b {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #444;
  line-height: 40px;
}
.main_colum_img {
  height: 150px;
  margin: 20px 0;
}
.main_colum_img img {
  width: 100%;
  height: 100%;
  display: block;
  transition: .5s;
}
.main_colum_img img:hover {
  transform: translateY(-5px) translateZ(10px);
  box-shadow: 5px 5px 5px #888;
  cursor: pointer;
}

</style>